import First from '../assests/First-min.webp';
import Second from '../assests/Second.jpg';
// import Third from '../assests/Third.jpg';

export const Data = [
    {
      image: First,
      heading : "Trusted Top Tier Logistics Partner",
      subHeading : "(Proven records for your progressive business)",
      button : "GET STARTED"
    },
    {
      image: Second,
      heading : "Trusted Top Tier Logistics Partner",
      subHeading : "(Proven records for your progressive business)",
      button : "GET STARTED"
    },
    // {
    //   image: Third,
    //   heading : "Trusted Top Tier Logistics Partner",
    //   subHeading : "(Proven records for your progressive business)",
    //   button : "GET STARTED"
    // }
  ];